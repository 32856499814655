.container{
    width: 100%;
    height: 100%;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.603);
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
}
.modalWind{
    width: 80%;
    height: 40vw;
    background-color: rgba(255, 255, 255, 0.788);
    border-radius: 2vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}
.header{
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 2vw;
    width: 70%;
    text-align: center;
    margin-top: 5vw;
}
.imgContainer{
    display: flex;
    align-items: center;
    justify-content: center;
}
.img{
    width: 13vw;
}
.stroke{
    height: 1vw;
}
.close{
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 2vw;
    position: absolute;
    top: 1vw;
    right: 1.2vw;
    color: black;
    transition: all .5s;
    opacity: .9;
    cursor: pointer;
}
.close:hover{
    transform: scale(103%);
    opacity: 1;
}

@media (max-width:768px) {
    .modalWind{
        width: 90%;
        height: auto;
        padding: 5vh 0;
    }
    .header{
        font-family: var(--font-family);
        font-weight: 600;
        font-size: 4vw;
        width: 70%;
        text-align: center;
        margin-bottom: 3vh;
        
    }
    .imgContainer{
        flex-direction: column;
        gap: 3vh;
    }
    .img{
        width: 70%;
    }
    .stroke{
        display: none;
    }
    .close{
        font-size: 7vw;
        right: 2vh;
        top:1.7vh;
    }
}