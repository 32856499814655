.container{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 0 8vw 0;
    box-sizing: border-box;
    background-color: black;
    position: relative;
}
.bac{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 195vh;
    z-index: 0;
    pointer-events: none;

  }
  .circle{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 210vh;
    z-index: 1;
      pointer-events: none;
  }
.header{
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 3vw;
    color: white;
    opacity: .8;
    margin-bottom: 5vw;

    z-index: 5;
}
.number{
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 2vw;
    color: white;
    opacity: .8;
    margin-bottom: 5vw;
    z-index: 5;
    margin: 2vw 0 0 0;
    transition: all .5s;
    cursor: pointer;
    text-decoration:none;
}
.number:hover{
    transform: scale(105%);
    color:white
}
.telegram{
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 2vw;
    color: white;
    opacity: .8;
    margin-bottom: 5vw;
    z-index: 5;
    border: 1px solid #29A0DA;
    padding: .7vw 5vw;
    border-radius: 5vw;
    margin: 2vw 0 0 0;
    transition: all .5s;
    cursor: pointer;
    text-decoration:none;
}
.telegram:hover{
    background-color: #29A0DA;
    color: rgb(255, 255, 255);
    transform: scale(102%);
}
.whatsapp{
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 2vw;
    color: white;
    opacity: .8;
    margin-bottom: 5vw;
    z-index: 5;
    border: 1px solid #56C964;
    padding: .7vw 5vw;
    border-radius: 5vw;
    margin: 2vw 0 0 0;
    transition: all .5s;
    cursor: pointer;
    text-decoration:none;
}
.whatsapp:hover{
    background-color: #56C964;
    color: rgb(255, 255, 255);
    transform: scale(102%);
}
@media (max-width:768px) {
    .container{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0 0 8vh 0;
        box-sizing: border-box;
        background-color: black;
        position: relative;
    }
    .bac{
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 140vh;
        z-index: 1;
        opacity: .8;
      }
      .circle{
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 150vh;
        z-index: 1;
        opacity: 1;
      }
    .header{
        font-family: var(--font-family);
        font-weight: 600;
        font-size: 7vw;
        color: white;
        opacity: .8;
        margin: 10vh 0 5vh 0;
    
        z-index: 5;
    }
    .number{
        font-family: var(--font-family);
        font-weight: 600;
        font-size: 4vw;
        color: white;
        opacity: .8;
        margin-bottom: 5vw;
        z-index: 5;
        margin: 2vw 0 0 0;
        transition: all .5s;
        cursor: pointer;
    }
    .number:hover{
        transform: scale(105%);
        color:white
    }
    .telegram{
        font-family: var(--font-family);
        font-weight: 600;
        font-size: 4vw;
        color: white;
        opacity: .8;
        margin-bottom: 5vw;
        z-index: 5;
        border: 1px solid #29A0DA;
        padding: 1vh 10vh;
        border-radius: 5vw;
        margin: 2vh 0 0 0;
        transition: all .5s;
        cursor: pointer;
    }
    .telegram:hover{
        background-color: #29A0DA;
        color: rgb(255, 255, 255);
        transform: scale(102%);
    }
    .whatsapp{
        font-family: var(--font-family);
        font-weight: 600;
        font-size: 4vw;
        color: white;
        opacity: .8;
        margin-bottom: 5vw;
        z-index: 5;
        border: 1px solid #56C964;
        padding: 1vh 10vh;
        border-radius: 5vw;
        margin: 2vh 0 0 0;
        transition: all .5s;
        cursor: pointer;
    }
    .whatsapp:hover{
        background-color: #56C964;
        color: rgb(255, 255, 255);
        transform: scale(102%);
    }
}