.container{
    width: 100%;
    height: auto;
    background-color: black;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    overflow: hidden;
}
.header{
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 3vw;
    color: white;
    opacity: .8;
    margin-bottom: 5vw;
    margin-top: 5vw;
    z-index: 5;
}
.card{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10%;
    margin-top: 2vw;
    z-index: 5;
}
.card2{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10%;
    margin-top: 2vw;
    z-index: 5;
}
.imgContainer{
    width: 45%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.imgContainer2{
    width: 45%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.textContainer{
    width: 55%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.textContainer2{
    width: 55%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.imgRight{
    width: 45%;
}
.textContainer span{
    width: 50%;
    padding: 3vw 3vw;
    background-color: rgba(255, 255, 255, 0.067);
    z-index: 2;
    color: rgba(255, 255, 255, 0.745);
    font-family: var(--font-family);
    font-weight: 300;
    border-radius: 2vw;
    font-size: 1.1vw;
}
.textContainer2 span{
    width: 48%;
    padding: 3vw 3vw;
    background-color: rgba(255, 255, 255, 0.067);
    z-index: 2;
    color: rgba(255, 255, 255, 0.745);
    font-family: var(--font-family);
    font-weight: 300;
    border-radius: 2vw;
    font-size: 1.1vw;
}
.textContainer b{
    display: block;
}
.textContainer2 b{
    display: block;
}
.circle{
    width: 100%;
    position: absolute;
    top: -12%;
    left: 0;
    z-index: 0;
      pointer-events: none;
}
.circle2{
    width: 100%;
    height: 135%;
    position: absolute;
    top: -15%;
    left: 0;
    z-index: 2;
      pointer-events: none;
    opacity: 1;
}
@media (max-width:768px) {
    .container{
        width: 100%;
        height: auto;
        background-color: black;
        margin: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        overflow: hidden;
    }
    .header{
        font-family: var(--font-family);
        font-weight: 600;
        font-size: 7vw;
        color: white;
        width: 90%;
        text-align: center;
        opacity: .8;
        margin-bottom: 5vw;
        margin-top: 10vh;
        z-index: 5;
    }
    .card{
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
        gap: 10%;
        margin-top: 2vw;
        z-index: 5;
    }
    .card2{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 10%;
        margin-top: 2vw;
        z-index: 5;
    }
    .imgContainer{
        width: 90%;
        display: flex;
        align-items: center;
        margin-top: 10vh;
        justify-content: center;
    }
    .imgContainer2{
        width: 90%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 10vh;
    }
    .textContainer{
        width: 90%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .textContainer2{
        width: 90%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .imgRight{
        width: 70%;
    }
    .textContainer span{
        width: 90%;
        padding: 7vh 3vh;
        background-color: rgba(255, 255, 255, 0.067);
        z-index: 2;
        color: rgba(255, 255, 255, 0.745);
        font-family: var(--font-family);
        font-weight: 300;
        border-radius: 2vh;
        font-size: 4vw;
        text-align: center;
        margin-top: 5vh;
    }
    .textContainer2 span{
        width: 90%;
        padding: 7vh 3vh;
        background-color: rgba(255, 255, 255, 0.067);
        z-index: 2;
        color: rgba(255, 255, 255, 0.745);
        font-family: var(--font-family);
        font-weight: 300;
        border-radius: 2vh;
        font-size: 4vw;
        text-align: center;
        margin-top: 5vh;
    }
    .textContainer b{
        display: block;
    }
    .textContainer2 b{
        display: block;
    }
    .circle{
        width: 100%;
        height: 300vh;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
    }
    .circle2{
        width: 100%;
        height: 135%;
        position: absolute;
        top: -15%;
        left: 0;
        z-index: 2;
        opacity: 1;
    }
}