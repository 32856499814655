.container{
    width: 100%;
    height: auto;
    background-color: black;
    display: flex;
    z-index: 5;
    gap: 0%;
    padding: 5vw 0;
    z-index: 6;
}

.textContainer{
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-left: 15%;
}
.imgContainer{
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.mobImg{
    width: 60%;z-index: 6;
}
.header{
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 2.2vw;
    color: white;
    margin: 0;
    z-index: 6;
}
.header1{
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 1.8vw;
    color: white;
    margin: 1vw 0 0 0;
    z-index: 6;
}
.buttonContainer{
    width: 100%;
    height: 4vw;
    display: flex;
    gap: 8%;
    margin-top: 4vw;
    z-index: 6;
}
.button{
    height: 100%;
    width: 30%;
    border: 1px solid gray;
    border-radius: 1vw;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8%;
    transition: all .5s;
    cursor: pointer;
}
.button:hover{
    background-color: rgba(255, 255, 255, 0.623);
    color: rgb(0, 0, 0);
}
.apple{
    height: 50%;
}
.buttonType{
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 1.6vw;
    color: white;
}

@media (max-width:768px) {
    .container{
        width: 100%;
        height: auto;
        background-color: black;
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
        z-index: 5;
        gap: 0%;
        padding: 10vh 0 0 0;
        z-index: 6;
    }
    
    .textContainer{
        width: 90%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-left: 0;
    }
    .imgContainer{
        width: 90%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .mobImg{
        width: 60%;z-index: 6;
    }
    .header{
        font-family: var(--font-family);
        font-weight: 600;
        font-size: 7vw;
        text-align: center;
        color: white;
        margin: 5vh 0 0 0;
        z-index: 6;
        opacity: .8;
    }
    .header1{
        font-family: var(--font-family);
        font-weight: 600;
        font-size: 5vw;
        color: white;
        margin: 1vh 0 0 0;
        z-index: 6;
        opacity: .8;
    }
    .buttonContainer{
        width: 100%;
        height: 9vh;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5%;
        margin-top: 4vh;
        z-index: 6;
        opacity: .8;
    }
    .button{
        height: 100%;
        width: 45%;
        border: 1px solid gray;
        border-radius: 2vh;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8%;
        transition: all .5s;
        cursor: pointer;
    }
    .button:hover{
        background-color: rgba(255, 255, 255, 0.623);
        color: rgb(0, 0, 0);
    }
    .apple{
        height: 60%;
    }
    .buttonType{
        font-family: var(--font-family);
        font-weight: 600;
        font-size: 4.5vw;
        color: white;
    }
}