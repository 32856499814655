.container{
    width: 100%;
    height: auto;
    background-color: black;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5vw 0;
}
.header{
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 3vw;
    color: white;
    opacity: .8;
    margin-bottom: 5vw;

    z-index: 5;
}
.cardContainer{
    display: flex;
    gap: 3vw;
    margin-top: 3vw;
}
.card{
    width: 12vw;
    height: 12vw;
    padding: 3vw 1vw;
    background-color: rgba(255, 255, 255, 0.067);
    z-index: 2;
    color: rgba(255, 255, 255, 0.745);
    font-family: var(--font-family);
    font-weight: 700;
    border-radius: 2vw;
    font-size: 1.1vw;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    box-sizing: border-box;
    transition: all .5s;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; 
}
.card:hover{
    background-color: rgba(255, 255, 255, 0.623);
    color: rgb(0, 0, 0);
}

@media (max-width:768px) {
    .container{
        width: 100%;
        height: auto;
        background-color: black;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 5vw 0;
    }
    .header{
        font-family: var(--font-family);
        font-weight: 600;
        font-size: 7vw;
        width: 90%;
        text-align: center;
        color: white;
        opacity: .8;
        margin: 8vh 0;
    }
    .cardContainer{
        display: flex;
        flex-direction: column;
        gap: 3vw;
        margin-top: 3vw;
    }
    .card{
        margin-top: 3vh;
        width: 90vw;
        height: 40vw;
        padding: 3vh 5vh;
        line-height: 27px;
        background-color: rgba(255, 255, 255, 0.067);
        z-index: 2;
        color: rgba(255, 255, 255, 0.581);
        font-family: var(--font-family);
        font-weight: 500;
        border-radius: 6vw;
        font-size: 4.3vw;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        box-sizing: border-box;
        transition: all .5s;

        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
         -khtml-user-select: none; /* Konqueror HTML */
           -moz-user-select: none; /* Old versions of Firefox */
            -ms-user-select: none; /* Internet Explorer/Edge */
                user-select: none; 
    }
    .card:hover{
        background-color: rgba(255, 255, 255, 0.623);
        color: rgb(0, 0, 0);
    }
}