.container{
    width: 100%;
    height: 4vw;
    background-color: #06191f3a;
    box-shadow:  0px 15px 26px -10px rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 999;
    position: fixed;
}
.logoContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1vw;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; 
}
.textContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.text1{
    font-family: var(--font-family);
    font-weight: 600;
    transition: all .3s;
    opacity: .8;
    font-size: .7vw;
    color: white;
    border-bottom: 1px solid gray;
    width: 80%;
    text-align: center;
    padding-bottom: .2vw;
}
.text2{
    font-family: var(--font-family);
    font-weight: 600;
    transition: all .3s;
    opacity: .8;
    font-size: .7vw;
    color: white;
    padding-top: .05vw;
}
.text1:hover,.text2:hover{
    opacity: 1;
}
.logo{
    height: 3vw;
    margin-left: 2vw;
    transition: all .3s;
    cursor: pointer;
}
.logo:hover{
    transform: scale(105%);
}
.nav{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2vw;
    margin-right: 2vw;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; 
}
.Link{
    font-family: var(--font-family);
    font-weight: 600;
    cursor: pointer;
    transition: all .3s;
    opacity: .8;
    font-size: 1.1vw;
    color: white;
    text-decoration: none;
}
.Link:hover{
    transform: scale(103%);
    opacity: 1;
}
.num{
    font-size: 1vw;
}
.butgerTrigger,.close{
    display: none;
}
.cityContainer{
    display: none;
}
.mobileNumber{
    display: none;
}
@media (max-width:768px){
    .cityContainer{
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 10vh;
        left: 0;
    }
    .city1{
        font-family: var(--font-family);
        font-weight: 600;
        transition: all .3s;
        opacity: .8;
        font-size: 6vw;
        color: white;
        border-bottom: 1px solid gray;
        width: 40%;
        text-align: center;
        padding-bottom: 1vh;
    }
    .city2{
        font-family: var(--font-family);
        font-weight: 600;
        transition: all .3s;
        opacity: .8;
        font-size: 4vw;
        color: white;
        padding-top: 1vh;
        text-align: center;
    }
    .textContainer{
        display: none;
    }
    .container{
        width: 100%;
        height: 9vh;
        background-color: #06191fd3;
        box-shadow:  0px 15px 26px -10px rgba(0, 0, 0, 0.7);
        display: flex;
        justify-content: space-between;
        align-items: center;
        z-index: 999;
        position: fixed;
    }
    .logo{
        height: 6vh;
        margin-left: 4vw;
        transition: all .3s;
        cursor: pointer;
    }
    .logo:hover{
        transform: scale(105%);
    }
    .nav{
        position: absolute;
        top: 0;
        left: -100vw;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 100vh;
        width: 100%;
        background-color: rgb(0, 0, 0);
        transition:  transform .3s ease-in-out;
        z-index: 100;
        gap: 5vh;
    }
    .nav::before{
        z-index: 0;
        content: '';
        width: 150vw;
        height: 150vw;
        position: absolute;
        background:radial-gradient(rgba(0, 114, 145, 0.158),rgba(2, 51, 59, 0.212),transparent,transparent,transparent);
        animation: animate 15.5s linear infinite;
        
    }
    .navActive{
        transform: translateX(100%);
    }
    .Link{
        font-family: var(--font-family);
        font-size: 7vw;
        font-weight: 600;
        cursor: pointer;
        transition: all .3s;
        opacity: .8;
    }
    .mobileNumber{
        display: block;
        text-decoration: none;
        color: white;
        font-family: var(--font-family);
        font-size: 4vw;
        font-weight: 500;
        cursor: pointer;
        transition: all .3s;
        opacity: .8;
        margin-right: 5vw;
    }
    .Link:hover{
        transform: scale(103%);
        opacity: 1;
    }
    .num{
        font-size: 6vw;
    }
    .butgerTrigger{
        z-index: 9;
        display: block;
        margin-right: 4vw;
        font-size: 6vw;
        font-weight: 600;
        opacity: .8;
        font-family: var(--font-family);
        color: rgb(233, 250, 255);
        transform: rotate(90deg); /* Поворачиваем элемент на 90 градусов */
    }
    .close{
        display: block;
        position: absolute;
        top: 3vh;
        right: 3vh;
        z-index: 9999;
        font-size: 5vh;
        font-weight: 600;
        opacity: .8;
        color: #effbffd3;
        font-family: var(--font-family);
    }

}
@media (min-width:1480px) {
    .Link{
        font-size: .8vw;
    }
    .logo{
        height: 2.7vw;
    }
}