html,body,#root,.App{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  height: 100%;
  width: 100%;

}
.App{
  background-color: black;

  width: 100%;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
     -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none; 
}
.popup-open {
  overflow: hidden;
  height: 100vh;
  width: 100vw;
}


.webHub{
  color: rgb(0, 0, 0);
  position: absolute;
  top: -100px;
  left: 0;
  z-index: 999;
}
