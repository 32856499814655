.container{
    width: 100%;
    height: auto;
    background-color: black;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5vw 0;
    overflow: hidden;
}
.bac{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 0;
    top: -20%;
}
.circle{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;
    top: -40%;
}
.header{
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 3vw;
    color: white;
    opacity: .8;
    margin-bottom: 5vw;

    z-index: 5;
}
.card{
    width: 70%;
    height: auto;
    padding: 3vw 1vw;
    background-color: rgba(255, 255, 255, 0.067);
    z-index: 2;
    color: rgba(255, 255, 255, 0.745);
    font-family: var(--font-family);
    font-weight: 700;
    border-radius: 2vw;
    font-size: 1.1vw;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    box-sizing: border-box;
    transition: all .5s;
    margin-top: 3vw;
}
.imgContainer{
    width: 27%;
    height: 100%;
    border-right: 1px solid gray;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}
.cardImg{
    width: 50%;
    margin: 0;
}
.cardHeader{
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 1vw;
}
.cardHeder2{
    margin: .5vw 0 0 0;
}
.button{
    width: 75%;
    padding: .5vw 0;
    text-decoration: none;
    font-family: var(--font-family);
    font-weight: 700;
    border-radius: 2vw;
    border: 1px solid gray;
    margin-top: 1vw;
    transition: all .5s;
    cursor: pointer;
}
.button:hover{
    background-color: rgba(255, 255, 255, 0.623);
    color: rgb(0, 0, 0);
}
.price1Container{
    height: 100%;
    width: 36%;
    border-right: 1px solid gray;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.priceHeader{
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 1.5vw;
    width: 80%;
    padding: 1vw 0;
    margin: 0;
}
.priceType{
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 1vw;
    margin-top: 1vw;
    font-size: 1.3vw;
    border-top:  1px solid rgb(51, 51, 51);
    width: 70%;
    padding: 1vw 0;
}
.priceDecr{
    font-family: var(--font-family);
    font-weight: 300;
    font-size: 1vw;
    width: 80%;
    padding:0 0 .1vw 0;

}
.price2Container{
    height: 100%;
    width: 36%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.price3Container{
    height: 100%;
    width: 72%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}
.priceDecription{
    font-family: var(--font-family);
    font-weight: 300;
    font-size: 1.3vw;
    text-align: left;
    width: 80%;
    padding:3vw;
}
.btn1{
    width: 40%;
}

@media (max-width:768px) {
    .container{
        width: 100%;
        height: auto;
        background-color: black;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 5vw 0;
        overflow: hidden;
    }
    .bac{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 0;
        top: -20%;
    }
    .circle{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 1;
        top: -40%;
    }
    .header{
        font-family: var(--font-family);
        font-weight: 600;
        font-size: 7vw;
        color: white;
        opacity: .8;
        margin: 6vh 0;
    
        z-index: 5;
    }
    .card{
        width: 90%;
        height: auto;
        padding: 5vh 1vw;
        background-color: rgba(255, 255, 255, 0.067);
        z-index: 2;
        color: rgba(255, 255, 255, 0.745);
        font-family: var(--font-family);
        font-weight: 700;
        border-radius: 5vw;
        font-size: 1.1vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        box-sizing: border-box;
        transition: all .5s;
        margin-top: 5vh;
    }
    .imgContainer{
        width: 90%;
        height: auto;
        border-right: none;
        border-bottom: 1px solid gray;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-bottom: 6vh;
    }
    .cardImg{
        width: 50%;
        margin: 0;
    }
    .cardHeader{
        font-family: var(--font-family);
        font-weight: 600;
        font-size: 5vw;
    }
    .cardHeder2{
        margin: .5vw 0 0 0;
    }
    .button{
        width: 85%;
        padding: 1.5vh 0;
        text-decoration: none;
        font-family: var(--font-family);
        font-weight: 700;
        border-radius: 5vh;
        border: 1px solid gray;
        margin-top: 1vw;
        transition: all .5s;
        cursor: pointer;
        font-size: 4vw;
    }
    .button:hover{
        background-color: rgba(255, 255, 255, 0.623);
        color: rgb(0, 0, 0);
    }
    .price1Container{
        height: 100%;
        width: 90%;
        border-right: none;
        border-bottom: 1px solid gray;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-bottom: 3vh;
    }
    .priceHeader{
        font-family: var(--font-family);
        font-weight: 700;
        font-size: 5vw;
        width: 80%;
        padding: 1vw 0;
        margin: 0;
        margin-top: 3vh;
    }
    .priceType{
        font-family: var(--font-family);
        font-weight: 700;
        font-size: 3.7vw;
        margin-top: 1vw;
        border-top:  1px solid rgb(51, 51, 51);
        width: 70%;
        padding: 2vh 0;
    }
    .priceDecr{
        font-family: var(--font-family);
        font-weight: 300;
        font-size: 3.5vw;
        width: 90%;
        padding:0 0 1vh 0;
    
    }
    .price2Container{
        height: 100%;
        width: 90%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .price3Container{
        height: 100%;
        width: 90%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .priceDecription{
        font-family: var(--font-family);
        font-weight: 300;
        font-size: 4vw;
        text-align: left;
        width: 80%;
        padding:3vw;
        text-align: center;
        margin-top: 3vh;
    }
    .btn1{
        width: 80%;
        margin-top: 3vh;
    }
}