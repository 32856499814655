.container{
    width: 100%;
    height:auto;
    position: relative;
    z-index: 1;
    background-color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    overflow: hidden;
}
.circle{
    width: 100%;
    position: absolute;
    top: -12%;
    left: 0;
    z-index: 0;
      pointer-events: none;
}
.circle1{
    width: 100%;
    position: absolute;
    top: -12%;
    left: 0;
    z-index: 0;
      pointer-events: none;
}
.circle2{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    opacity: .7;
      pointer-events: none;
}
.carContainer{
    width: 60%;
    position: absolute;
    top: 17vw;
    left: 20%;
    z-index: 11222;
}
.carRelative{
    position: relative;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
}
.car{
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    z-index: 2;
}
.koleso{
    position: absolute;
    z-index: 3;
    top: 11.5vw;
    left: 45.5vw;
    width: 17%;
}
.koleso2{
    position: absolute;
    z-index: 3;
    top: 11.5vw;
    left: 6.9vw;
    width: 17%;
}
.header{
    font-size: 5.5vw;
    opacity: 0;
    color: white;
    z-index: 1121;
    font-family: var(--font-family);
    font-weight: 600;
    margin-top: 10vw;
}
.header2{
    font-size: 3vw;
    opacity:0;
    color: white;
    z-index: 1121;
    font-family: var(--font-family);
    font-weight: 600;
    margin-top: 18vw;
}
.description{
    width: 70%;
    padding: 3vw 3vw;
    background-color: rgba(255, 255, 255, 0.067);
    z-index: 2;
    color: rgba(255, 255, 255, 0.745);
    font-family: var(--font-family);
    font-weight: 300;
    border-radius: 2vw;
    font-size: 1.1vw;
    margin-top: 5vw;
}

@media (max-width:768px){
    .container{
        width: 100%;
        height:auto;
        position: relative;
        z-index: 1;
        background-color: black;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        overflow: hidden;
    }
    .circle{
        width: auto;
        height: 90%;
        position: absolute;
        top: 0;
        left: -20%;
        z-index: 0;
    }
    .circle1{
        width: auto;
        height: 100%;
        position: absolute;
        top: 0;
        left: -50%;
        z-index: 0;
    }
    .circle2{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        opacity: .6;
    }
    .carContainer{
        width: 160%;
        position: absolute;
        top: 30vh;
        left: -80%;
        z-index: 11222;
    }
    .carRelative{
        position: relative;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
    }
    .car{
        position: absolute;
        top:0;
        left: 0;
        width: 100%;
        z-index: 2;
    }
    .koleso{
        position: absolute;
        z-index: 3;
        top: 31vw;
        left: 121vw;
        width: 17%;
    }
    .koleso2{
        position: absolute;
        z-index: 3;
        top: 11.5vw;
        left: 6.9vw;
        width: 17%;
    }
    .header{
        font-size:10vw;
        opacity: .7;
        color: white;
        z-index: 1121;
        font-family: var(--font-family);
        font-weight: 600;
        margin-top: 20vh;
    }
    .header2{
        font-size: 7vw;
        opacity: .7;
        color: white;
        z-index: 1121;
        font-family: var(--font-family);
        font-weight: 600;
        margin-top: 35vh;
        text-align: center;
  
    }
    .description{
        width: 90%;
        padding: 5vh 7vw;
        background-color: rgba(255, 255, 255, 0.067);
        z-index: 2;
        color: rgba(255, 255, 255, 0.745);
        font-family: var(--font-family);
        font-weight: 300;
        border-radius: 2vw;
        font-size: 4vw;
        margin-top: 5vw;
        box-sizing: border-box;
        text-align: center;
    }
}